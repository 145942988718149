import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

// 
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
// ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/element.css'
Vue.use(ElementUI);
// ----------------------------------
// 网页标题
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)
// ----------------------------------

// 配置全局常用函数
import { clickgate, retJSONArr, fn_retjson, alertMess, handlereq, fn_reTrim,
setAuth,
} from './network/defaults.js'
Vue.prototype.$alertMess = alertMess
Vue.prototype.$handlereq = handlereq
Vue.prototype.$clickgate = clickgate
Vue.prototype.$retJSONArr = retJSONArr
Vue.prototype.$fn_retjson = fn_retjson
Vue.prototype.$fn_reTrim = fn_reTrim
Vue.prototype.$setAuth = setAuth
import { request} from './network/request.js'
Vue.prototype.$myRuquest = request
// ---------------------------------------------


Vue.config.productionTip = false

new Vue({
  router,
  store,

  
  render: h => h(App)
}).$mount('#app')
