import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
	state: {
		// 输入框只能输入数字
		Numberoninput: "value=value.replace(/[^0-9.]/g,'')",
		
		cancelTokenArr: [],
		layoutbg: '#ffffff',
		surplus: (window.innerHeight - 249),
		token: '',
	},
	getters: {},
	mutations: {
		setlayoutbg(state, layoutbg) {
			state.layoutbg = layoutbg
		},
		addCancelToken(state, cancel) {
			state.cancelTokenArr.push(cancel)
		},
		set_token(state, token) {
			state.token = token
		},
		clearCancelToken(state) {
			state.cancelTokenArr.forEach(fn => {
				fn()
			})
			state.cancelTokenArr = []
		},
	},
	actions: {},
	modules: {}
})
