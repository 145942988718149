//引入axios，进行二次封装
import axios from 'axios'
import store from '@/store'

//引入进度条
// import nprogress from 'nprogress'

//start:进度条开始 done：进度条结束
//引入进度条的样式
// import "nprogress/nprogress.css"

export function request(config) {

	// 1.创建axios的实例
	const instance = axios.create({
		baseURL: 'http://hsmes.400365.net',  
		// baseURL: '/api',  
		// timeout: 60000,
		// withCredentials: true
	});
	instance.interceptors.request.use(config => {
		config.headers['Content-Type'] = 'application/json'
		config.headers['version'] = '2.0.1'
		config.headers['token'] = localStorage.token || ''
		// 添加正在请求的接口数量
		config.cancelToken = new axios.CancelToken(cancel => {
			store.commit('addCancelToken', cancel)
		})
		return config
	}, err => {
		if (err && err.message === 'Operation canceld by the user.') {
			return;
		}
		return Promise.reject(err);
	});

	// 2.2.响应拦截
	instance.interceptors.response.use(res => {
		return res.data
	}, err => {
		return;
	})
	// 3.发送真正的网络请求
	return instance(config)
}

