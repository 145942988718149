<template>
	<div class="head_area FlexBetween">
		<div class="phead_left FlexAlign">
			<div class="logo_"> 
				鸿顺生产预测系统 
			</div>
			<div class="head_search FlexAlign">
				<div class="icon">
					<i class="iconfont icon-a-lujing16"></i>
				</div>
				<el-input
				  placeholder="请输入内容"
				  v-model="search_val"
				  clearable>
				</el-input>
			</div>
		</div>
		
		
		<div class="phead_right FlexAlign"> 
			<div class="keyslist FlexAlign">
				
				<el-tooltip class="item" effect="dark" content="清除缓存" placement="bottom-end">
				    <div class="keysBtn FlexRow " @click="Clear_Cache">
				    	<i class="iconfont icon-qinglihuancun"></i>
				    </div>
				</el-tooltip>
				<div class="keysBtn FlexRow ">
					<i class="iconfont icon-a-lujing17"></i>
				</div>
				<div class="keysBtn FlexRow ">
					<i class="iconfont icon-a-lujing18"></i>
				</div>
				<div class="keysBtn FlexRow notice">
					<i class="iconfont icon-a-lujing19"></i>
				</div>
				
			</div>
			
			<el-dropdown @command="handleCommand">
				<div class="user_area FlexRow">
					<div class="user_img">
						<img src="https://img.js.design/assets/img/6465e92aa6bed30be4ab117a.png#4bccc265f45bdf957dc32396c9f9cf20" alt="">
					</div>
					<div class="user_name">
						王小明
					</div>
					<div class="more_icon">
						<i class="el-icon-arrow-down"></i>
					</div>
				</div>
				<el-dropdown-menu slot="dropdown"  >
					<el-dropdown-item :command="1" >
						退出登录
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		
		
	</div>
</template>

<script>
	import {
		userLogout,
		clearcache,
		congetAuth,
	} from "network/role";
	export default {
		name: 'phead',
		data() {
			return {
				search_val: '',
			}
		},
		methods: {
			// 获取登录用户角色菜单权限
			getconAuth(){
				congetAuth().then(res=>{
					if (!this.$handlereq(res)) return
					let {
						root,auth
					} = res.data
					localStorage.setItem('admin_root', root);
					localStorage.setItem('admin_auth', JSON.stringify(auth));
				})
			},
			handleCommand(flag){
				if(!this.$clickgate()) return;
				if(flag == 1) {
					this.userLogout()
				}
			},
			// 退出登录
			userLogout() {
				userLogout().then(res=>{
					if (!this.$handlereq(res)) return
					this.$alertMess('操作成功')
					this.$store.commit('clearCancelToken')
					setTimeout(()=>{
						localStorage.clear()
						this.$router.push({ path: '/login' })
					}, 1000)
					let resobj = res.data || ''
				})
			},
			// 清除缓存
			Clear_Cache(){
				clearcache().then(res=>{
					if (!this.$handlereq(res)) return
					this.$alertMess('清除缓存成功')
					this.getconAuth()
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	
	
	.head_area{
		width: 100vw;
		height: 60px;
		min-width: 960px;
		position: sticky;
		top: 0;
		background-color: #fff;
		z-index: 100;
		box-shadow: 0 0 5px 0 rgba(0,0,0,.15);
		padding: 0 41px 0 26px;
	}
	.phead_right{
		.keyslist{
			.keysBtn{
				margin-left: 20px;
				width: 32px;
				height: 32px;
				border-radius: 100%;
				border: 1px solid rgba(237, 241, 244, 1);
				cursor: pointer;
				position: relative;
				i{
					color: rgba(78, 89, 105, 1);
				}
			}
			.keysBtn.notice::after{
				top: -2px;
				right: -2px;
				content: '';
				display: block;
				position: absolute;
				width: 6px;
				height: 6px;
				border-radius: 100%;
				background: rgba(245, 63, 63, 1);
			}
		}
		.user_area{
			margin-left: 28px;
			cursor: pointer;
			.user_img{
				width: 32px;
				height: 32px;
				border-radius: 100%;
				overflow: hidden;
				box-shadow: 0 2px 5px 0 rgba(0,0,0,.4);
				img{
					width: 100%;
					height: 100%;
				}
			}
			.user_name{
				max-width: 180px;
				font-size: 14px;
				font-weight: 400;
				color: rgba(78, 89, 105, 1);
				text-align: left;
				margin: 0 12px 0 8px;
			}
			.more_icon{
				i{
					color: rgba(56, 56, 56, 1);
				}
			}
		}
	}  
	
	.phead_left{
		.logo_{
			font-size: 18px;
			font-weight: 550;
			letter-spacing: 0px;
			line-height: 26.06px;
			color: rgba(0, 0, 0, 1);
			text-align: left;	
			margin-right: 55px;
		}
		.head_search{
			.icon{
				cursor: pointer;
				margin-top: -3px;
				i{
					color: rgba(73, 73, 73, 1);
					font-size: 13px;
				}
			}
			
		}
	}   
	.head_search ::v-deep .el-input__inner{
		border: none;
		font-size: 13px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 18.82px;
		color: rgba(170, 170, 170, 1);
		text-align: left;
	}
</style>