
//引入封装好的axios
import {request} from "./request";
// 获取菜单列表
export function congetMenu(){
	return request({
		url: '/adminapi/config/getMenu',
		method: 'post',
		data: {},
	})
}
// 获取登录用户角色菜单权限
export function congetAuth(){
	return request({
		url: '/adminapi/config/getAuth',
		params: {},
	})
}
// 删除角色
export function delete_role(data){
	return request({
		url: '/adminapi/auth.role/delete',
		method: 'post',
		data,
	})
}
// 编辑角色
export function edit_role(data){
	return request({
		url: '/adminapi/auth.role/edit',
		method: 'post',
		data,
	})
}
// 新增角色
export function add_role(data){
	return request({
		url: '/adminapi/auth.role/add',
		method: 'post',
		data,
	})
}

// 角色列表
export function lists_role(params){
	return request({
		url: '/adminapi/auth.role/lists',
		params,
	})
}

// 角色详情
export function detail_role(params){
	return request({
		url: '/adminapi/auth.role/detail',
		params,
	})
}


// 账号登录
export function userLogin(data){
	return request({
		url: '/adminapi/login/account',
		method: 'post',
		data,
	})
}

// 退出登录
export function userLogout(data){
	return request({
		url: '/adminapi/login/logout',
		method: 'post',
		data: data || {},
	})
}

// 清除缓存
export function clearcache(){
	return request({
		url: '/adminapi/settings.system.cache/clear',
		params: {},
	})
}