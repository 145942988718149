<template>
	<!-- 无查看权限 -->
	<div class="noauth_box FlexColumn" :style="austyle">
		<img src="@/assets/img/noauth.png" alt="">
		<div class="txt">
			暂无查看权限
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			austyle: {
				type: String,
				default: '',
			},
		},
		data() {
			return {}
		},
	}
</script>

<style lang="scss" scoped>
	.noauth_box{
		width: 100%;
		height: 100%;
		img{
			width: 200px;
			max-height: 200px;
		}
		.txt{
			font-size: 16px;
			color: #999;
			margin-top: 10px;
		}
	}
</style>