<template>
	<!--  -->
	<div class="bar_left">
		<el-menu :default-active="activePath" class="el-menu-bar_left" :collapse="menucollect" @select="handleSelect"
			:unique-opened="true" :collapse-transition="true">
			<template v-for="(item1, index1) in barlist">


				<template v-if="item1.children && item1.children.length">
					<el-submenu :index="item1.path">
						<template slot="title">
							<div class="title_Area FlexAlign">
								<div class="t_icon FlexRow">
									<i class="iconfont" :class="item1.icon"></i>
								</div>
								<span slot="title">
									{{item1.title}}
								</span>
							</div>
						</template>
						<div class="menu-item" v-for="(item2, index2) in item1.children">
							<el-menu-item :index="item2.path">
								{{item2.title}}
							</el-menu-item>
						</div>
					</el-submenu>
				</template>
				<template v-else>
					<el-menu-item class="separate" :index="item1.path">
						<div class="title_Area FlexAlign">
							<div class="t_icon FlexRow">
								<i class="iconfont" :class="item1.icon"></i>
							</div>
							<span slot="title">
								{{item1.title}}
							</span>
						</div>
					</el-menu-item>
				</template>
			</template>
		</el-menu>
		<div class="foldbtn FlexAlign">
			<i :class="iconFold" @click="handlemenuFold"></i>
		</div>



	



	</div>
</template>

<script>
	export default {
		name: "Bar",
		data() {
			return {
				barlist: [{
						title: '概况中心',
						icon: 'icon-a-lujing1',
						path: '/overview/orderkanban',
					},
					{
						title: '生产订单',
						icon: 'icon-bingji',
						path: '/prodorder',
						children: [{
							title: '订单列表',
							path: '/prodorder/orderlist',
						}, ],
					},
					{
						title: '产能预测',
						icon: 'icon-a-lujing11',
						path: '/prodprediction',
						children: [{
								title: '拉丝预测',
								path: '/prodprediction/wiredrawing',
							},
							{
								title: '压扁预测',
								path: '/prodprediction/flattening',
							},
							{
								title: '调直预测',
								path: '/prodprediction/straightening',
							},
						],
					},
					{
						title: '产能报表',
						icon: 'icon-a-lujing12',
						path: '/capareport',
						children: [{
								title: '订单产能报表',
								path: '/capareport/ordercapreport',
							},
							{
								title: '拉丝产能报表',
								path: '/capareport/wirecapreport',
							},
							{
								title: '压扁产能报表',
								path: '/capareport/flatcapreport',
							},
							{
								title: '调直产能报表',
								path: '/capareport/straigcapreport',
							},
						],
					},
					{
						title: '产能设置',
						icon: 'icon-a-lujing13',
						path: '/capacsetting',
						children: [{
								title: '产线设置',
								path: '/capacsetting/prodlineset',
							},
							{
								title: '减产管理',
								path: '/capacsetting/prodreduset',
							},
							{
								title: '增产管理',
								path: '/capacsetting/prodincreset',
							},
						],
					},
					{
						title: '基础数据',
						icon: 'icon-a-lujing14',
						path: '/basicdata',
						children: [{
								title: '客户数据',
								path: '/basicdata/customerdata',
							},
							{
								title: '物料数据',
								path: '/basicdata/materialdata',
							},
							
						],
					},
					{
						title: '系统设置',
						icon: 'icon-a-bingji1',
						path: '/sysettings',
						children: [{
								title: '账号管理',
								path: '/sysettings/accountset',
							},
							{
								title: '权限角色',
								path: '/sysettings/permirole',
							},
							{
								title: 'KIS配置',
								path: '/sysettings/kisconfig',
							},
						],
					},
				],
				activePath: "/overview/orderkanban",
				iconFold: 'el-icon-s-fold',
				menucollect: false,
			};
		},
		created() {
			this.activePath = this.$route.path
		},

		methods: {
			handleSelect(index) {
				this.$router.replace(index).catch((err) => err);
				this.activePath = index;
			},
			handlemenuFold() {
				this.menucollect = !this.menucollect

				this.$emit("memucollect", {
					flag: this.menucollect
				})
				if (this.menucollect) {
					this.iconFold = 'el-icon-s-unfold'
				} else {
					this.iconFold = 'el-icon-s-fold'
				}

			}
		},
	};
</script>

<style scoped lang="scss">
	@import "./pnav.scss";

	::v-deep .separate.el-menu-item {
		height: 50px !important;
	}
</style>
<style>
	.active a,
	.active span {
		color: var(--themeColors) !important;
	}

	.active i,
	.active span {
		color: var(--themeColors) !important;
	}
</style>