import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import layout from '@/views/layout.vue'
Vue.use(VueRouter)
// import {
// 	congetAuth,
// } from "network/role";

const routes = [
	{
		path: '',
		redirect: '/login',
	},
	{
		path: '/login',
		component: () => import('@/views/login/login.vue'),
		meta: {
			title: '用户登录' // 标题设置
		},
	},
	{
		path: '/layout',
		component: () => import('@/views/layout.vue'),
		children: [
			{
				path: '/overview/orderkanban',
				component: () => import('@/views/overview/orderkanban/orderkanban.vue'),
				meta: {
					title: '概况中心-主页看板' // 标题设置
				},
			},
			{
				path: '/prodorder',
				component: () => import('@/views/prodorder/prodorder.vue'),
				children: [{
						path: '',
						redirect: '/prodorder/orderlist',
					},
					{
						path: '/prodorder/orderlist',
						component: () => import('@/views/prodorder/orderlist/orderlist.vue'),
						meta: {
							title: '生产订单-订单列表' // 标题设置
						},
					},
				]
			},


			{
				path: '/prodprediction',
				component: () => import('@/views/prodprediction/prodprediction.vue'),
				children: [{
						path: '',
						redirect: '/prodprediction/wiredrawing',
					},
					{
						path: '/prodprediction/wiredrawing',
						component: () => import('@/views/prodprediction/wiredrawing/wiredrawing.vue'),
						meta: {
							title: '产能预测-拉丝预测' // 标题设置
						},
					},
					{
						path: '/prodprediction/flattening',
						component: () => import('@/views/prodprediction/flattening/flattening.vue'),
						meta: {
							title: '产能预测-压扁预测' // 标题设置
						},
					},
					{
						path: '/prodprediction/straightening',
						component: () => import('@/views/prodprediction/straightening/straightening.vue'),
						meta: {
							title: '产能预测-调直预测' // 标题设置
						},
					},
				]
			},

			{
				path: '/capareport',
				component: () => import('@/views/capareport/capareport.vue'),
				children: [{
						path: '',
						redirect: '/capareport/ordercapreport',
					},
					{
						path: '/capareport/ordercapreport',
						component: () => import('@/views/capareport/ordercapreport/ordercapreport.vue'),
						meta: {
							title: '产能报表-订单产能报表' // 标题设置
						},
					},
					{
						path: '/capareport/wirecapreport',
						component: () => import('@/views/capareport/wirecapreport/wirecapreport.vue'),
						meta: {
							title: '产能报表-拉丝产能报表' // 标题设置
						},
					},
					{
						path: '/capareport/flatcapreport',
						component: () => import('@/views/capareport/flatcapreport/flatcapreport.vue'),
						meta: {
							title: '产能报表-压扁产能报表' // 标题设置
						},
					},
					{
						path: '/capareport/straigcapreport',
						component: () => import('@/views/capareport/straigcapreport/straigcapreport.vue'),
						meta: {
							title: '产能报表-调直产能报表' // 标题设置
						},
					},
				]
			},

			{
				path: '/capacsetting',
				component: () => import('@/views/capacsetting/capacsetting.vue'),
				children: [{
						path: '',
						redirect: '/capacsetting/prodlineset',
					},
					{
						path: '/capacsetting/prodlineset',
						component: () => import('@/views/capacsetting/prodlineset/prodlineset.vue'),
						meta: {
							title: '产能设置-产线设置' // 标题设置
						},
					},
					{
						path: '/capacsetting/prodreduset',
						component: () => import('@/views/capacsetting/prodreduset/prodreduset.vue'),
						meta: {
							title: '产能设置-减产管理' // 标题设置
						},
					},
					{
						path: '/capacsetting/prodincreset',
						component: () => import('@/views/capacsetting/prodincreset/prodincreset.vue'),
						meta: {
							title: '产能设置-增产管理' // 标题设置
						},
					},
				]
			},

			{
				path: '/basicdata',
				component: () => import('@/views/basicdata/basicdata.vue'),
				children: [{
						path: '',
						redirect: '/basicdata/customerdata',
					},
					{
						path: '/basicdata/customerdata',
						component: () => import('@/views/basicdata/customerdata/customerdata.vue'),
						meta: {
							title: '基础数据-客户数据' // 标题设置
						},
					},
					{
						path: '/basicdata/materialdata',
						component: () => import('@/views/basicdata/materialdata/materialdata.vue'),
						meta: {
							title: '基础数据-物料数据' // 标题设置
						},
					},
				]
			},

			{
				path: '/sysettings',
				component: () => import('@/views/sysettings/sysettings.vue'),
				children: [{
						path: '',
						redirect: '/sysettings/accountset',
					},
					{
						path: '/sysettings/accountset',
						component: () => import('@/views/sysettings/accountset/accountset.vue'),
						meta: {
							title: '系统设置-账号管理' // 标题设置
						},
					},
					{
						path: '/sysettings/permirole',
						component: () => import('@/views/sysettings/permirole/permirole.vue'),
						meta: {
							title: '系统设置-权限角色' // 标题设置
						},
					},
					{
						path: '/sysettings/kisconfig',
						component: () => import('@/views/sysettings/kisconfig/kisconfig.vue'),
						meta: {
							title: '系统设置-KIS配置' // 标题设置
						},
					},
				]
			},

		]
	},

	
]

const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	base: '/dist',
	routes,
})


router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	store.commit('clearCancelToken')
	next()
});


export default router