<template>
	<div>
		<phead></phead>


		<div class="layout_box FlexStart">
			<div class="layout_left">
				<plnav></plnav>
			</div>
			<div class="layout_right" :style="'background: ' + right_bg">
				<div class="right_box" v-show="lookload">
					<template v-if="lookUp">
						<router-view></router-view>
					</template>
					<template v-else>
						<noauth></noauth>
					</template>
				</div>
			</div>
		</div>

	</div>

</template>
<script>
	import phead from "@/components/common/pagetop/phead.vue";
	import plnav from "@/components/common/pageleft/plnav.vue";
	import noauth from "@/components/publics/noauth.vue";
	import {
		congetAuth,
	} from "network/role";
	export default {
		components: {
			phead,
			plnav,
			noauth,
		},
		data() {
			return {
				right_bg: '#ffffff',
				
				lookUp: false,
				lookload: false,
				Timer: null,
			}
		},
		computed: {
			layoutbg() {
				return this.$store.state.layoutbg;
			}
		},
		watch: {
			layoutbg(old_val, new_val) {
				this.right_bg = old_val
			},
			// 路由页面发生变化时
			$route(to,from){
				this.setpageAuth()
			},
		},
		created() {
			this.getconAuth()
		},
		methods: {
			// 获取查看权限
			setpageAuth(){
				clearTimeout(this.Timer)
				let path = this.$route.path || ''
				this.lookload = false
				if(path == '/overview/orderkanban'){
					this.lookUp = true
				}
				else {
					// auth_bool：true/false/SuperAdmin
					let auth_bool = this.$setAuth(path, 'view')
					if(auth_bool == 'SuperAdmin') {
						this.lookUp = true
					}
					else {
						this.lookUp = auth_bool
					}
				}
				this.Timer = setTimeout(()=>{
					this.lookload = true
				}, 500)
			},
			// 获取登录用户角色菜单权限
			getconAuth(){
			
				congetAuth().then(res=>{
					if (!this.$handlereq(res)) return
					let {
						root,auth
					} = res.data
					localStorage.setItem('admin_root', root);
					localStorage.setItem('admin_auth', JSON.stringify(auth));
					this.setpageAuth()
				})
			},
		},
		
	}
</script>


<style lang="scss" scoped>
	.layout_box {
		overflow: hidden;
		width: 100vw;
		height: calc(100vh - 60px);

		.layout_left {
			height: 100%;

		}

		.layout_right {
			height: 100%;
			flex: 1;
			// max-width: calc(100vw - 200px);
			padding: 10px;
			min-width: 960px;

			.right_box {
				width: 100%;
				height: 100%;
				overflow: auto;
			}
		}
	}
</style>