import { Message } from 'element-ui';
import router from '@/router'
import store from '@/store'

// 返回权限
export function setAuth(pathkey, rolekey){
	let root = localStorage.admin_root || ''
	if(root == 1) {  // 超级管理员 SuperAdmin
		return 'SuperAdmin'
	}
	else {
		// 获取缓存中的权限
		let auth = localStorage.admin_auth || ''
		// 解析 字符串转对象
		let auth_obj = retJSONArr(auth)
		// 权限对象存在
		if(auth_obj && typeof(auth_obj) == 'object') {
			// 找出需要的权限数组
			let auth_arr = auth_obj[pathkey] || []
			// 返回需要的某个权限
			return auth_arr.some(v=>v == rolekey)
		}
		else {
			return false
		}
	}
}
// 
export function handlereq(res) {
	if (res) {
		if(res.code === -1) {  // 未登录
			store.commit('clearCancelToken')
			localStorage.clear()
			router.push('/login')
			return false
		}
		else if (res.code === 1) { // 请求成功
			return true
		} 
		else { // 请求发生错误
			alertMess(res.msg, 'error')
			return false
		}
	}			
}

export function try_catch(dosothing){
    try{
        dosothing();
    } 
	catch (err) {
		alertMess(err.msg, 'warning', 6000)
        console.log('出现异常！', err);
    }
}

export function alertMess(message, type, duration, offset, showClose) {
	if(message != undefined) {
		Message({
			message,
			type: type ? type : 'success', // success warning error
			duration: duration != undefined ? duration : 2000,
			offset: offset != undefined ? offset : 100,
			showClose: showClose != undefined ? showClose : true,
		})
	}
}

// 时间戳转换时间
export	function timestampToTime(timestamp, flag) {
	if(timestamp) {
		let newVla = (timestamp + '').length > 10 ? timestamp : timestamp * 1000;
		var date = new Date(newVla); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
		
		var year = date.getFullYear();
		var month = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
		let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
		
		let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
		let minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
		let second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
		let time = ''
		if(flag) {
			time = `${year}-${month}-${day} ${hour}:${minute}:${second}`
		}
		else {
			time = `${year}-${month}-${day}`
		}
		return time
	}
	else {
		 return ''
	}
	//注意：如果是Unix时间戳记得乘以1000。比如：PHP函数time()获得的时间戳就要乘以1000。			
}		

export function fn_reTrim(str){
	return (str + '').trim()
}
export function randomArr(min, max) {
	if(min && max) {
		return Math.round(Math.random() * (max - min)) + min;
	}
}

export function retJSONArr(str) {
	let res = str
	if (str && typeof (str) == 'string') {
		res = JSON.parse(str)
	}
	return res;
}

export function fn_retjson(obj) {
	if(obj != undefined) {
		return JSON.parse(JSON.stringify(obj))
	}
	else {
		return ''
	}
}

let flagGate = true;	
let flagTimer = null;
export function clickgate(second) {
	if(flagGate) {
		flagGate = false
		flagTimer = setTimeout(()=>{
			flagGate = true
		}, second || 1200)
		return true
	}
	else {
		return false
	}
}

export function checkStr(str, type) { // 常用正则验证，注意type大小写
    switch (type) {
        case 'phone': // 手机号码
            return /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(str)
        case 'tel': // 座机
            return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str)
        case 'card': // 身份证
            return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str)
        case 'pwd': // 密码以字母开头，长度在6~18之间，只能包含字母、数字和下划线
            return /^[a-zA-Z]\w{5,17}$/.test(str)
        case 'postal': // 邮政编码
            return /[1-9]\d{5}(?!\d)/.test(str)
        case 'QQ': // QQ号
            return /^[1-9][0-9]{4,9}$/.test(str)
        case 'email': // 邮箱
            return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str)
        case 'money': // 金额(小数点2位)
            return /^\d*(?:\.\d{0,2})?$/.test(str)
        case 'URL': // 网址
            return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(str)
        case 'IP': // IP
            return /((?:(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d)\\.){3}(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d))/.test(str)
        case 'date': // 日期时间
            return /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2})(?:\:\d{2}|:(\d{2}):(\d{2}))$/.test(str) ||
                /^(\d{4})\-(\d{2})\-(\d{2})$/.test(str)
        case 'number': // 数字
            return /^[0-9]$/.test(str)
        case 'english': // 英文
            return /^[a-zA-Z]+$/.test(str)
        case 'chinese': // 中文
            return /^[\u4E00-\u9FA5]+$/.test(str)
        case 'lower': // 小写
            return /^[a-z]+$/.test(str)
        case 'upper': // 大写
            return /^[A-Z]+$/.test(str)
        case 'HTML': // HTML标记
            return /<("[^"]*"|'[^']*'|[^'">])*>/.test(str)
        default:
            return true
    }
}


// 导出表格
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export function exportExcel(fname, TableId) {
	
	let eleid = TableId || "#exportTab";
	/* generate workbook object from table */
	var xlsxParam = {
		raw: true
	} // 导出的内容只做解析，不进行格式转换
	var table = document.querySelector(eleid).cloneNode(true)
	console.log(FileSaver,'FileSaver')
	console.log(XLSX,'XLSX')
	if(table) {
		let elfixed = table.querySelector('.el-table__fixed')
		if(elfixed) {
			table.removeChild(table.querySelector('.el-table__fixed')) //这里是双下划线
		}
		var wb = XLSX.utils.table_to_book(table, xlsxParam)
		/* get binary string as output */
		var wbout = XLSX.write(wb, {
			bookType: 'xlsx',
			bookSST: true,
			type: 'array'
		})
		try {
			let fileName = `${fname || '鸿顺表格'}-${getCurrentfield()}.xlsx`
			FileSaver.saveAs(new Blob([wbout], {
				type: 'application/octet-stream'
			}), fileName)
		} catch (e) {
			if (typeof console !== 'undefined') {
				console.log(e, wbout)
			}
		}
		return wbout
	}
}



export function getCurrentWeek() {
	var week1 = new Date(new Date().getTime() - (3600 * 1000 * 24 * (new Date().getDay() - 1)))
	let str1 = timestampToTime(week1)
	let newVal1 = `${str1} 00:00:00`
	
	var week2 = new Date(new Date().getTime() + (3600 * 1000 * 24 * (7 - new Date().getDay())))
	let str2 = timestampToTime(week2)
	let newVal2 = `${str2} 23:59:59`
	
	return [ToggleTime(newVal1), ToggleTime(newVal2)]		
};

export function searchTime(){
	var date = new Date();
	var year = date.getFullYear() // 年
	var month = date.getMonth() + 1; // 月
	var day = date.getDate(); // 日
	var hour = date.getHours(); // 时
	// 昨天   2022-08-06 00:00:00  2022-08-06 23:59:59 
	let yesterday = []
	// 今天
	let today = []
	let y1 = year + '-' + month + '-' + (day - 1) + ' ' + '00:00:00';
	let y2 = year + '-' + month + '-' + (day - 1) + ' ' + '23:59:59';
	let t1 = year + '-' + month + '-' + day + ' ' + '00:00:00';
	let t2 = year + '-' + month + '-' + day + ' ' + '23:59:59';
	var date1 = Date.parse(y1);
	var date2 = Date.parse(y2)
	var date3 = Date.parse(t1)
	var date4 = Date.parse(t2)
	yesterday = [date1, date2]
	today = [date3, date4]
	var week = date.getDay()
	let weeks = getCurrentWeek()
	// 
	let val = {
		shortcuts: [
		{
			text: '昨天',
			onClick(picker) {
				picker.$emit('pick', yesterday);
			}
		}, 
		{
			text: '今天',
			onClick(picker) {
				picker.$emit('pick', today);
			}
		}, 
		{
			text: '上周',
			onClick(picker) {
				picker.$emit('pick', getlastWeek());
			}
		}, 
		{
			text: '本周',
			onClick(picker) {
				picker.$emit('pick', weeks);
			}
		}, 
		
		{
			text: '上月',
			onClick(picker) {
				picker.$emit('pick', lastMonthDate());
			}
		}, 
		{
			text: '本月',
			onClick(picker) {
				var date = new Date(),
					y = date.getFullYear(),
					m = date.getMonth();
				var firstDay = new Date(y, m, 1);
				const end = new Date();
				const start = new Date(new Date().getMonth(), 0);
				picker.$emit('pick', [firstDay, end]);
			}
		}, 
		
		{
			text: '上季度',
			onClick(picker) {
				picker.$emit('pick', getLastQuarter());
			}
		},
		{
			text: '本季度',
			onClick(picker) {
				picker.$emit('pick', reutnrjidu());
			}
		},
		
		{
			text: '上一年',
			onClick(picker) {
				picker.$emit('pick', previousYear());
			}
		},
		{
			text: '本年',
			onClick(picker) {
				const end = new Date();
				const start = new Date(new Date().getFullYear(), 0);
				picker.$emit('pick', [start, end]);
			}
		},  
		
		]
	}
	
	
	
	return val
};
// 本季度
export function reutnrjidu() {

	let now = new Date(); //当前日期 
	var nowDayOfWeek = now.getDay(); //今天本周的第几天
	var nowDay = now.getDate(); //当前日
	let nowMonth = now.getMonth(); //当前月 
	let nowYear = now.getFullYear(); //当前年 
	let jd = Math.ceil((nowMonth + 1) / 3);
	let startTime = Date.parse(new Date(nowYear, (jd - 1) * 3, 1))
	let endTime = Date.parse(new Date(nowYear, jd * 3, 0))
	return [startTime, endTime]
}

// 上季度
export function getLastQuarter(){    
	var today = new Date();    
	var dayMSec = 24 * 3600 * 1000;    
    //得到上一个季度的第一天    
    var lastQuarterFirstDay = new Date(today.getFullYear() , today.getMonth() - 3 , 1);    
     //得到本月第一天    
    var nowMonthFirstDay = new Date(today.getFullYear() , today.getMonth(), 1);    
    //得到上一个季度的最后一天的毫秒值    
    var lastQuarterLastDayMSec = nowMonthFirstDay.getTime() - 1 * dayMSec;    
    var lastQuarterLastDay = new Date(lastQuarterLastDayMSec);    
	
	
	let t1 = timestampToTime(lastQuarterFirstDay, 1)
	let start_time = Date.parse(t1)
	let end_time = lastQuarterLastDayMSec
	return [start_time, end_time]
    // return lastQuarterFirstDay.format('yyyy-MM-dd') +","+lastQuarterLastDay.format('yyyy-MM-dd');    
}   
// 上周
export function getlastWeek() {

	var today = new Date();
	var year = today.getFullYear(); //本年 
	var month = today.getMonth() + 1; //本月
	var day = today.getDate(); //本日
	var newDate = new Date(year + "-" + month + "-" + day + " 00:00:00"); //年月日拼接

	var nowTime = newDate.getTime(); //当前的时间戳
	var weekDay = newDate.getDay(); //当前星期 0.1.2.3.4.5.6 【0 = 周日】
	var oneDayTime = 24 * 60 * 60 * 1000; //一天的总ms

	var newVal1 = nowTime - (weekDay + 6) * oneDayTime; //上周一的时间戳
	var newVal2 = nowTime - (weekDay + 0) * oneDayTime; //上周日的时间戳
	return [newVal1, newVal2]
};

//获取上个月时间
export function lastMonthDate() {
	var nowdays = new Date();
	var year = nowdays.getFullYear();
	var month = nowdays.getMonth();
	if (month == 0) {
		month = 12;
		year = year - 1;
	}
	if (month < 10) {
		month = '0' + month;
	}
	var myDate = new Date(year, month, 0);
	var startDate = `${year}-${month}-01 00:00:00`  //上个月第一天
	var endDate = `${year}-${month}-${myDate.getDate()} 23:59:00` //上个月最后一天

	let t1 = Date.parse(startDate)
	let t2 = Date.parse(endDate)
	return [t1, t2]
}

export function tentampTo(timestamp) {
	if (timestamp) {
		let len1 = (timestamp + '').length
		return len1 > 10 ? parseInt(timestamp / 1000) : timestamp;
	}
	else {
		return ''
	}
};
/* 上一年 */
export function previousYear(){
	/* 获取现在的年份 */
	let dateTime = new Date().getFullYear(); 
	dateTime = new Date(new Date().setFullYear(dateTime-1)).getFullYear()
	let start_time = `${dateTime}-01-01 00:00:00`
	let end_time = `${dateTime}-12-31 23:59:59`
	let t1 = Date.parse(start_time)
	let t2 = Date.parse(end_time)
	return [t1,t2]
}

export function getThisYear() {
	let arr = []
	const start = new Date(new Date().getFullYear(), 0);
	const end = new Date();

	let timestamp1 = Date.parse(start)
	let timestamp2 = Date.parse(end)
	let t1 = ((timestamp1 + '').slice(0, 10)) * 1
	let t2 = ((timestamp2 + '').slice(0, 10)) * 1
	arr.push(t1)
	arr.push(t2)
	return JSON.stringify(arr)
}

// 日期时间 转换 时间戳
export function ToggleTime(time) {
	if(time) {
		// 时间 转换 时间戳 int 类型  10位数时间戳
		let new_time = ''
		var date1 = (Date.parse('2022-01-01 00:00:00') + '').slice(0, 10);
		if (isNaN(date1)) {
			let val1 = time.split('-')
			if(val1.length == 3) {
				let newVal = `${val1[0]}/${val1[1]}/${val1[2]}`
				new_time = Date.parse(newVal)
			}
			else{
				new_time = Date.parse(time)
			}
		} else {
			new_time = Date.parse(time)
			
		}
		// 返回时间戳
		return new_time
	}
				
};

export function fn_overload(num) {
	if (typeof (num) != 'number') {
		num = num * 1
	}
	if (num > 999) {     //字数是否过千
		var sen = num / 1000;    //过千处理
		var vals = sen.toFixed(1)    //保留一位小数
		return vals + "k"      //字符串拼接
	} else {
		return num
	}
}